import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24ac266c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Inplace = _resolveComponent("Inplace")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.layoutStore.isImpersonated)
      ? (_openBlock(), _createBlock(_component_Inplace, {
          key: 0,
          class: ""
        }, {
          display: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "p-button-outlined p-button-sm",
              label: _ctx.$t('menu.button.impersonateUser')
            }, null, 8, ["label"])
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.impersonationUserName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.impersonationUserName) = $event)),
                class: "mr-1 username-input",
                name: "userName",
                placeholder: _ctx.$t('menu.impersonateUser_placeholder'),
                type: "email",
                onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.impersonateUser()), ["enter"]))
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_Button, {
                class: "p-button-outlined p-button-sm",
                disabled: !_ctx.isValidInput(),
                label: _ctx.$t('common.login'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.impersonateUser()))
              }, null, 8, ["disabled", "label"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Button, {
      class: _normalizeClass(["p-button-text p-button-rounded", { 'p-button-info': _ctx.layoutStore.isImpersonated }]),
      title: _ctx.$t('menu.button.refreshUserData_title'),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.refreshUserInfo()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          icon: ['far', 'rotate-right'],
          size: "lg"
        })
      ]),
      _: 1
    }, 8, ["class", "title"]),
    (_ctx.layoutStore.isImpersonated)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          class: "p-button-text p-button-rounded p-button-info",
          title: _ctx.$t('menu.button.cancelImpersonation_title'),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancelImpersonation()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'arrow-right-from-bracket'],
              size: "lg"
            })
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}