<template>  
    <div class="cashback-progress-wrapper mb-4 p-4">
        <div class="wallet-amount-text mb-2">            
            <img v-lazy="vm.icon.url" :alt="vm.icon.alt" class="wallet-icon" />
            <span class="heading-2">{{ vm.headerTitle }}</span>
        </div>
        <div class="mb-2">
            {{ vm.headerSubtitle }}            
        </div>        
        <div>
            <Button class="p-button-brand-secondary px-3"
                    icon="pi pi-arrow-right ml-2"
                    icon-pos="right"
                    @click="openUrl(vm.headerButtonLink.url)"
                    :label="vm.headerButtonLink.text" />                    
        </div>     
    </div>
    <div class="row">
        <div class="col col-6">
            <h3 class="mb-3">{{ vm.pageTitle }}</h3>            
        </div>
        <div class="col col-6 d-flex justify-content-end">
            <img v-lazy="vm.icon.url" :alt="vm.icon.alt" class="wallet-icon-small" />
            <div class="wallet-amount-small">{{ formatCurrency(vm.totalAutodeskSaved) }}</div>            
        </div>
        <div v-for="product in mappedProds" :key="product" class="col col-12 col-sm-6 col-lg-4">
            <div v-if="product.cashback && product.cashbackText && product.price && product.priceText" class="reward-wrapper pb-4">
                <CircularProgress :item="product" />
                <div class="reward-name">
                    {{ formatCurrency(product.cashback) }} {{ product.cashbackText }}
                </div>
                <div class="reward-price">
                    <p v-if="product.price">
                        {{ product.priceText }} {{ formatCurrency(product.price) }}
                    </p>
                </div>
                <Button class="p-button-success p-button-sm"
                        :label="product.buttonLink.text"
                        @click="openUrl(product.buttonLink.url)" />
            </div>
            <div v-else class="reward-wrapper pb-4">
                <CircularProgress :item="product" />
                <div class="reward-name">
                    {{ formatProductName(product.name, product.brand) }}
                </div>
                <div class="reward-price">
                    <p v-if="product.price">
                        {{$t('myCadac.myrewards.tile.priceText')}} {{ formatCurrency(product.price) }}
                    </p>
                </div>                
                <Button class="p-button-success p-button-sm"
                        :label="$t('myCadac.myrewards.button.label')"
                        @click="openUrl(product.productPageUrl)" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
import CircularProgress from "@/components/rewards/circularProgress.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { IRewardsPageViewModel, Reward } from "@/types/viewModels/myCadac/rewardsPageViewModel";

@Component({ components: { BaseProductOverviewItemCard, CircularProgress } })
export default class RewardsPage extends BaseComponent {
	@Prop({
		type: Object as PropType<IRewardsPageViewModel>,
		required: true,
		default: {},
	})
    vm!: IRewardsPageViewModel;
    mappedProds!: Reward[];        

    created() {        
        this.mappedProds = [...this.vm.products, ...this.vm.cashbackRewardList].map((product) => ({
            ...product,
            percentage: this.calculatePercentageForProduct(product),
        }))        
    }

    calculatePercentageForProduct(product: Reward) {
        let percentage = 0;
        if (this.vm.totalAutodeskSaved) {
            percentage = Math.floor((this.vm.totalAutodeskSaved / product.price) * 100);
            // keep percentage between 0 and 100 
            percentage = Math.max(0, Math.min(percentage, 100));
        }
        return percentage;
    }
}
</script>
<style scoped lang="scss">
    .cashback-progress-wrapper {
        width: 100%;
        background: #91004B;
        color: #fff;

        .wallet-amount-text {
            font-size: 1.625rem;

            .wallet-icon {
                width: 3rem;
                height: 3rem;
                float: left;
                margin-right: 1rem;
            }
        }
    }

    .reward-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;        
    }

    .reward-name {
        font-size: 1.375rem;
        font-weight: 500;
        color: #1D1D1B;
        text-align: center;
    }

    .reward-price {
        color: #5C5C5A;
        text-align: center;
        font-size: 1.375rem;
        font-weight: 400;
    }

    .wallet-icon-small {
        height: 1.5rem;
        width: 1.5rem;
        float: left;
        margin: 5px 10px 0 0;
    }

    .wallet-amount-small {
        display: inline-block;
        font-size: 1.35rem;
        font-weight: bold;        
        color: #91004B;
    }

</style>
